import { useEffect, useState } from "react";

const getEndDate = (question) => {
  if (!question) {
    return null;
  }

  const normedMedian = question.community_prediction.full.q2;
  const ratio = question.possibilities.scale.deriv_ratio;

  const startDate = new Date(question.possibilities.scale.min);
  const endDate = new Date(question.possibilities.scale.max);

  const width = endDate.getTime() - startDate.getTime();

  const median = ratio !== 1 ? (ratio ** normedMedian - 1) / (ratio - 1) : normedMedian;

  const result = new Date(startDate.getTime() + width * median);

  return result;
}

const useFetchEndDate = () => {
  const [ question, setQuestion ] = useState();
  const strong = localStorage.getItem("strong-ai") === "true";

  const fetchQuestion = (strongUrl) => {
    fetch('https://aiclock-proxy.herokuapp.com/' + (strongUrl ? "strong" : ""))
      .then(res => res.json())
      .then(
        (result) => {
          setQuestion(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  useEffect(() => {
    fetchQuestion(strong);
    setInterval(
      () => fetchQuestion(strong),
      1000 * 60 * 60
    );  
  }, [strong]);

  
  return getEndDate(question);
}


export default useFetchEndDate;